const initBraintree = function (amount, allowAlternativePaymentMethods) {
    var form = document.getElementsByClassName('payment')[0];
    var payButton = document.getElementById("payButton");
    var nonce = document.getElementById("nonce").value;
    var currency = document.getElementById("currency").value;
    var language = document.getElementById("language").value;
    var countryCode = document.getElementById("countryCode").value;
    var googlePayMerchantId = document.getElementById("googlePayMerchantId").value;

    amount = amount.replace(",", "");
    document.getElementById('bt-dropin').innerHTML = '';
    var params = {
        authorization: nonce,
        container: '#bt-dropin',
        locale: language,
        dataCollector: true,
        threeDSecure: {
            authorization: nonce,
            version: 2
        },
    };
    if (allowAlternativePaymentMethods) {
        params.paypal = {
            flow: "checkout",
            amount: amount,
            currency: currency
        };

        params.googlePay = {
            googlePayVersion: 2,
            merchantId: googlePayMerchantId,
            transactionInfo: {
              totalPriceStatus: 'FINAL',
              totalPrice: amount,
              currencyCode: currency,
              countryCode: countryCode
            },
            allowedPaymentMethods: [
                {
                    type: 'CARD',
                    parameters: {
                        allowedAuthMethods: [
                            'PAN_ONLY',
                            'CRYPTOGRAM_3DS'
                        ],
                        allowedCardNetworks: [
                            'AMEX',
                            'DISCOVER',
                            'JCB',
                            'MASTERCARD',
                            'VISA'
                        ],
                        billingAddressRequired: true,
                        billingAddressParameters: {
                            format: 'FULL'
                        }
                    }
                }
            ]
        };

        params.applePay = {
            displayName: 'Simpson Travel',
            paymentRequest: {
              total: {
                label: 'Simpson Travel',
                amount: amount
              },
              currencyCode: currency
            }
        };
    };

    window.braintree.dropin.create(params, function (createErr, instance) {
        console.log('dropin-create executed.', createErr, instance);
        form.addEventListener('submit', function (event) {
            event.preventDefault();
            if (allowAlternativePaymentMethods && params.paypal) {
                document.querySelector('#amount').value = params.paypal.amount;
            }
            try {
                document.getElementById("popupFade").style.display = 'none';
                document.getElementById("popupLoader").style.display = 'none';
            }
            catch (ex) { }
            instance.requestPaymentMethod({ threeDSecure: { amount: amount } }, function (err, payload) {
                if (err) {
                    console.log('Error', err);
                    return;
                }
                // Add the nonce to the form and submit
                document.querySelector('#deviceData').value = payload.deviceData;
                document.querySelector('#nonce').value = payload.nonce;
                try {
                    document.getElementById("popupFade").style.display = 'block';
                    document.getElementById("popupLoader").style.display = 'block';
                }
                catch (ex) { }
                payButton.disabled = true;
                payButton.innerText = "processing...";
                form.submit();
            });
        });
    });
}


export default initBraintree;
